import React from "react";
// Libraries
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Parallax } from "react-scroll-parallax";
import { m } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Keyboard } from "swiper";

// Components
import Header, {
  HeaderCart,
  HeaderLanguage,
  HeaderNav,
  Menu,
  SearchBar,
} from "../../Components/Header/Header";
import Blockquote from "../../Components/Blockquote/Blockquote";
import Buttons from "../../Components/Button/Buttons";
import { fadeIn, fadeInLeft, zoomIn } from "../../Functions/GlobalAnimations";
import SocialIcons from "../../Components/SocialIcon/SocialIcons";
import FooterStyle01 from "../../Components/Footers/FooterStyle01";
import Overlap from "../../Components/Overlap/Overlap";
import SideButtons from "../../Components/SideButtons";
import { TextAnime, TiltBox } from "../../Components/FancyText/FancyText";
import CustomModal from "../../Components/CustomModal";

const data = [
  {
    icon: "feather-shopping-cart",
    title: "Wide Selection of Gear",
    description:
      "Explore a vast collection of top-quality sports gear, tailored for athletes of all levels and disciplines.",
  },
  {
    icon: "feather-shield",
    title: "Secure Payments",
    description:
      "Shop with confidence using our fully secure checkout, protecting your personal and payment information.",
  },
  {
    icon: "feather-truck",
    title: "Fast Shipping",
    description:
      "Benefit from multiple delivery options, including express shipping, to get your gear when you need it.",
  },
  {
    icon: "feather-tag",
    title: "Exclusive Deals",
    description:
      "Access special discounts and member-only offers on a wide range of products.",
  },
  {
    icon: "feather-help-circle",
    title: "Customer Support",
    description:
      "Our support team is available to help with any questions or issues, ensuring a smooth shopping experience.",
  },
  {
    icon: "feather-thumbs-up",
    title: "Trusted Quality",
    description:
      "Purchase confidently with products backed by real customer reviews and our commitment to quality.",
  },
];

// import ChatplusImgHeader from "/public/assets/img/project-chatplus/chatplus-header.jpg";

// Data
const SwiperImgData = [
  {
    img: "https://via.placeholder.com/1400x933",
  },
  {
    img: "https://via.placeholder.com/1400x933",
  },
  {
    img: "https://via.placeholder.com/1400x933",
  },
  {
    img: "https://via.placeholder.com/1400x933",
  },
];

const SocialIconsData = [
  {
    color: "#3b5998",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#ea4c89",
    link: "https://dribbble.com/",
    icon: "fab fa-dribbble",
  },
  {
    color: "#1769ff",
    link: "https://www.behance.net/",
    icon: "fab fa-behance",
  },
  {
    color: "#fe1f49",
    link: "https://www.instagram.com/",
    icon: "fab fa-instagram",
  },
];

const ProEcommerce = (props) => {
  return (
    <div style={props.style}>
      {/* Parallax Scrolling Start */}
      <div className="full-screen landscape:md:h-[600px] md:flex md:items-center overflow-hidden relative">
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute top-[1px] left-0 md:-top-[30px] w-full h-[100vh] landscape:md:h-[600px]"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url("/assets/img/project-chatplus/chatplus-header.jpg")`,
          }}
        ></Parallax>
        <Container className="relative h-full">
          <Row className="justify-center h-full">
            <Col
              lg={8}
              md={9}
              sm={10}
              xs={12}
              className="flex text-center flex-col justify-center py-32"
            >
              <span className="font-serif tracking-[5px] text-white uppercase font-medium mb-[35px] block sm:text-">
                Built a scalable MERN eCommerce platform with advanced search,
                streamlined checkout, and secure order management, enhancing
                user experience and business growth.
              </span>
              <h1 className="font-serif font-bold text-[130px] text-white text-shadow-double-large uppercase mb-0 leading-[120px] tracking-[-4px]  lg:text-[110px] lg:leading-[100px] md:text-[90px] md:leading-[85px] sm:text-[50px] sm:leading-[50px] sm:tracking-[-0.5px]">
                <span className="text-border text-border-width-2px fill-transparent block">
                  ProShop
                </span>
                website
              </h1>
            </Col>
          </Row>

          {/* Modal Component Start */}
          <CustomModal.Wrapper
            modalBtn={
              <div className="cursor-pointer text-center absolute bottom-[50px] left-1/2 -translate-x-1/2 flex justify-center sm:bottom-[30px]">
                <span className="w-[120px]  text-[34px] flex justify-center items-center">
                  <i className="line-icon-Video-5 text-[50px] text-white p-[15px] xs:p-[10px] rounded w-full bottom-[50px]"></i>
                </span>
              </div>
            }
          >
            <div className="w-[1020px] max-w-full relative rounded mx-auto">
              <div className="fit-video">
                <iframe
                  width="100%"
                  height="100%"
                  className="shadow-[0_0_8px_rgba(0,0,0,0.06)]"
                  controls
                  src="https://www.youtube.com/embed/Y01eageyvNM?si=p61Xoi3V3jWD57NA"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </CustomModal.Wrapper>
          {/* Modal Component End */}
        </Container>
      </div>
      {/* Parallax Scrolling End */}

      {/* Section Start */}
      <section
        id="about"
        className="py-[160px] bg-darkgray overflow-hidden lg:py-[120px] md:py-[90px] sm:py-[80px] xs:py-[50px]"
      >
        <Container>
          <Row>
            <Col xl={4} lg={5} md={6}>
              <m.h4
                className="font-serif font-semibold text-white -tracking-[1px] sm:text-center xxs:mb-[45px]"
                {...fadeIn}
              >
                <span className="block p-0 sm:text-center tracking-[-1px]">
                  Elevate Your Shopping Experience
                </span>
                <TextAnime
                  duration={3000}
                  color="#3433F3"
                  className="rotate-text text-left sm:text-center font-semibold my-0 mx-auto"
                  animation="slide"
                  data={[
                    "High-Quality Gear",
                    "Seamless Online Shopping",
                    "Expert Product Reviews",
                    "Secure Transactions",
                    "Fast Delivery Options",
                    "Exclusive Discounts",
                  ]}
                />
              </m.h4>
            </Col>
            <m.div
              className="sm:text-center col-xl-5 col-md-6 offset-xl-2 offset-lg-1"
              {...{ ...fadeIn, transition: { delay: 0.5 } }}
            >
              <span className="font-serif font-semibold text-white uppercase block mb-[15px]">
                About Pro Shop
              </span>
              <p className="text-xmd w-[95%] leading-[36px] md:w-full">
                Pro Shop is a cutting-edge eCommerce platform tailored for
                sports enthusiasts, offering a wide selection of premium gear
                and equipment. Designed with user experience in mind, Pro Shop
                provides effortless browsing, detailed product insights, and a
                secure checkout process. With flexible delivery options,
                exclusive discounts, and a responsive support team, Pro Shop
                makes it easy to find and purchase top-quality products for
                every sport and skill level. Shop confidently, knowing Pro Shop
                has everything you need to elevate your game.
              </p>

              <Buttons
                ariaLabel="button"
                href="https://barik-store.onrender.com/"
                className="font-medium font-serif uppercase btn-link after:h-[2px] text-[#FFD700] after:bg-darkgray hover:bg-[#FFD700] hover:text-black"
                color="#FFD700"
                target="_blank"
                title="Visit website"
                size="lg"
              />
            </m.div>
          </Row>
          <Row className="row-cols-1 row-cols-lg-4 row-cols-sm-2 mt-36 justify-center md:mt-24 landscape:md:mt-12 xs:mt-[65px] gap-y-10">
            {data.map((item, i) => {
              return (
                <m.div
                  key={i}
                  {...{ ...fadeIn, transition: { delay: 0.2 * i } }}
                >
                  <div className="flex justify-center items-center h-full">
                    <i
                      className={`${item.icon} align-middle text-[28px] text-white mr-[15px]`}
                    ></i>
                    <span className="font-serif font-semibold uppercase leading-[16px]">
                      {item.title}
                    </span>
                  </div>
                </m.div>
              );
            })}
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section
        id="my-role"
        className="py-[160px] bg-lightgray overflow-hidden lg:py-[120px] md:py-[90px] sm:py-[80px] xs:py-[50px]"
      >
        <Container>
          <Row>
            <Col xl={4} lg={5} md={6}>
              <m.h4
                className="font-serif font-semibold text-darkgray -tracking-[1px] sm:text-center xxs:mb-[45px]"
                {...fadeIn}
              >
                <span className="block p-0 sm:text-center tracking-[-1px]">
                  My Role and Tools
                </span>
              </m.h4>
            </Col>
            <m.div
              className="sm:text-center col-xl-5 col-md-6 offset-xl-2 offset-lg-1"
              {...{ ...fadeIn, transition: { delay: 0.5 } }}
            >
              <span className="font-serif font-semibold text-darkgray uppercase block mb-[15px]">
                Description
              </span>
              <p className="text-xmd w-[95%] leading-[36px] md:w-full">
                Pro Shop is an advanced eCommerce platform built for sports
                enthusiasts, utilizing the MERN stack to deliver a seamless
                shopping experience. The platform offers a vast selection of
                premium sports gear and equipment, designed to cater to athletes
                of all levels. With secure payment processing, fast shipping
                options, and exclusive member discounts, Pro Shop ensures a
                user-friendly and efficient shopping journey. Additionally, Pro
                Shop’s responsive customer support and user-driven reviews
                provide a trustworthy environment for buyers seeking quality
                sports products.
              </p>
            </m.div>
          </Row>
          <Row className="mt-36 justify-center md:mt-24 landscape:md:mt-12 xs:mt-[65px] gap-y-10">
            <Col lg={6}>
              <div className="font-serif font-semibold text-darkgray uppercase mb-[15px]">
                Technologies Used
              </div>
              <ul className="list-none text-darkgray leading-[28px]">
                <li>
                  MongoDB: Stores product data, user profiles, and orders in a
                  flexible document format for easy scalability.
                </li>
                <li>
                  Express.js: Manages API requests and server logic, providing
                  secure user authentication and data handling.
                </li>
                <li>
                  React: Powers the interactive UI with dynamic filtering, cart
                  updates, and a smooth checkout experience.
                </li>
                <li>
                  Node.js: Runs the backend, supporting efficient, scalable
                  server-side processing.
                </li>
                <li>
                  Redux: Manages global state for efficient data flow, enabling
                  a seamless experience across components.
                </li>
                <li>
                  Bootstrap: Ensures a responsive, consistent layout across
                  devices, enhancing UI accessibility and design.
                </li>
              </ul>
            </Col>

            <Col lg={6}>
              <div className="font-serif font-semibold text-darkgray uppercase mb-[15px]">
                Key Features
              </div>
              <ul className="list-none text-darkgray leading-[28px]">
                <li>
                  Advanced search and filtering for efficient product discovery.
                </li>
                <li>Secure checkout with multiple payment options.</li>
                <li>User reviews and ratings for informed buying decisions.</li>
                <li>
                  Responsive design for a seamless experience on all devices.
                </li>
                <li>
                  Global state management with Redux for smooth navigation.
                </li>
                <li>Order tracking and notifications for delivery updates.</li>
                <li>Exclusive deals and discounts for members.</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      {/* <section className="py-[130px] overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px]">
        <Container>
          <Row className="items-center xs:justify-center">
            <Col
              md={4}
              sm={4}
              className="text-right font-serif text-darkgray uppercase text-md font-medium sm:text-end xs:text-center xs:mb-[10px]"
            >
              Share this project
            </Col>
            <Col md={4} sm={2}>
              <div className="w-full h-[1px] bg-mediumgray xs:my-[15px]"></div>
            </Col>
            <Col md={4} sm={6}>
              <SocialIcons
                theme="social-icon-style-01"
                className="text-darkgray xs:justify-center"
                size="sm"
                iconColor="dark"
                data={SocialIconsData}
              />
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* Section End */}

      {/* Section Start */}
      {/* <section className="relative z-[1] bg-lightgray p-0 overflow-hidden">
        <Container fluid className="px-0">
          <Row sm={2} className="row-cols-1 justify-center xs:text-center gx-0">
            <Col className="relative">
              <Link
                to="/portfolio/single-project-page-05"
                className="portfolio_btn h-full px-28 py-16 justify-between items-center flex xl:p-12 md:inline-flex"
              >
                <div
                  className="portfolio_img absolute top-0 left-0 h-full w-full -z-[1] duration-150	bg-no-repeat bg-cover overflow-hidden bg-center"
                  style={{
                    backgroundImage: "url(https://via.placeholder.com/955x185)",
                  }}
                ></div>
                <div className="btn_icon font-medium text-darkgray font-serif mr-uto uppercase">
                  <i className="line-icon-Arrow-OutLeft duration-100	text-[40px] inline-block mr-[20px] align-middle	"></i>
                  <span className="text-sm inline-block md:hidden">
                    Prev project
                  </span>
                </div>
                <span className="portfolio_disc font-medium text-darkgray text-xlg font-serif block lg:text-[18px] lg:leading-[26px] md:text-left">
                  Model photography
                </span>
              </Link>
            </Col>
            <Col className="relative md:flex md:justify-end xs:block">
              <Link
                to="/portfolio/single-project-page-02"
                className="portfolio_btn h-full px-28 py-16 justify-between items-center flex xl:p-12 md:inline-flex"
              >
                <span className="portfolio_disc font-medium text-darkgray text-xlg font-serif block lg:text-[18px] lg:leading-[26px] md:text-right">
                  Daimler financial
                </span>
                <div
                  className="portfolio_img absolute top-0 left-0 h-full w-full -z-[1] duration-150	bg-no-repeat bg-cover overflow-hidden bg-center"
                  style={{
                    backgroundImage: "url(https://via.placeholder.com/955x185)",
                  }}
                ></div>
                <div className="btn_icon font-medium text-darkgray font-serif mr-uto uppercase">
                  <span className="text-sm inline-block md:hidden">
                    Next project{" "}
                  </span>
                  <i className="line-icon-Arrow-OutRight duration-100	text-[40px] inline-block ml-[20px] align-middle	"></i>
                </div>
              </Link>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* Section End */}
    </div>
  );
};

export default ProEcommerce;
