import React from "react";
// Libraries
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Parallax } from "react-scroll-parallax";
import { m } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Keyboard } from "swiper";

// Components
import Header, {
  HeaderCart,
  HeaderLanguage,
  HeaderNav,
  Menu,
  SearchBar,
} from "../../Components/Header/Header";
import Blockquote from "../../Components/Blockquote/Blockquote";
import Buttons from "../../Components/Button/Buttons";
import { fadeIn, fadeInLeft, zoomIn } from "../../Functions/GlobalAnimations";
import SocialIcons from "../../Components/SocialIcon/SocialIcons";
import FooterStyle01 from "../../Components/Footers/FooterStyle01";
import Overlap from "../../Components/Overlap/Overlap";
import SideButtons from "../../Components/SideButtons";
import { TextAnime, TiltBox } from "../../Components/FancyText/FancyText";
import CustomModal from "../../Components/CustomModal";

const data = [
  {
    icon: "feather-home",
    title: "Real-Time Bookings",
  },
  {
    icon: "feather-tag",
    title: "Dynamic Pricing",
  },
  {
    icon: "feather-layers",
    title: "Cabin Availability",
  },
  {
    icon: "feather-users",
    title: "User Management",
  },
];

// import ChatplusImgHeader from "/public/assets/img/project-chatplus/chatplus-header.jpg";

// Data
const SwiperImgData = [
  {
    img: "https://via.placeholder.com/1400x933",
  },
  {
    img: "https://via.placeholder.com/1400x933",
  },
  {
    img: "https://via.placeholder.com/1400x933",
  },
  {
    img: "https://via.placeholder.com/1400x933",
  },
];

const SocialIconsData = [
  {
    color: "#3b5998",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#ea4c89",
    link: "https://dribbble.com/",
    icon: "fab fa-dribbble",
  },
  {
    color: "#1769ff",
    link: "https://www.behance.net/",
    icon: "fab fa-behance",
  },
  {
    color: "#fe1f49",
    link: "https://www.instagram.com/",
    icon: "fab fa-instagram",
  },
];

const WildOasis = (props) => {
  return (
    <div style={props.style}>
      {/* Parallax Scrolling Start */}
      <div className="full-screen landscape:md:h-[600px] md:flex md:items-center overflow-hidden relative">
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute top-[1px] left-0 md:-top-[30px] w-full h-[100vh] landscape:md:h-[600px]"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url("/assets/img/project-chatplus/chatplus-header.jpg")`,
          }}
        ></Parallax>
        <Container className="relative h-full">
          <Row className="justify-center h-full">
            <Col
              lg={8}
              md={9}
              sm={10}
              xs={12}
              className="flex text-center flex-col justify-center py-32"
            >
              <span className="font-serif tracking-[5px] text-white uppercase font-medium mb-[35px] block sm:text-">
                Wild Oasis Management Dashboard
              </span>
              <h1 className="font-serif font-bold text-[130px] text-white text-shadow-double-large uppercase mb-0 leading-[120px] tracking-[-4px]  lg:text-[110px] lg:leading-[100px] md:text-[90px] md:leading-[85px] sm:text-[50px] sm:leading-[50px] sm:tracking-[-0.5px]">
                <span className="text-border text-border-width-2px fill-transparent block">
                  Wild Oasis
                </span>
                website
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Parallax Scrolling End */}

      {/* Section Start */}
      <section
        id="about"
        className="py-[160px] bg-darkgray overflow-hidden lg:py-[120px] md:py-[90px] sm:py-[80px] xs:py-[50px]"
      >
        <Container>
          <Row>
            <Col xl={4} lg={5} md={6}>
              <m.h4
                className="font-serif font-semibold text-white -tracking-[1px] sm:text-center xxs:mb-[45px]"
                {...fadeIn}
              >
                <span className="block p-0 sm:text-center tracking-[-1px]">
                  Cutting-Edge Solutions for Efficient Booking and Guest
                  Management
                </span>
                <TextAnime
                  duration={3000}
                  color="#3433F3"
                  className="rotate-text text-left sm:text-center font-semibold my-0 mx-auto"
                  animation="slide"
                  data={[
                    "Real-Time Bookings",
                    "Dynamic Pricing",
                    "Cabin Availability",
                    "User Management",
                    "Auto Check-in/Out",
                    "Guest Interaction",
                  ]}
                />
              </m.h4>
            </Col>
            <m.div
              className="sm:text-center col-xl-5 col-md-6 offset-xl-2 offset-lg-1"
              {...{ ...fadeIn, transition: { delay: 0.5 } }}
            >
              <span className="font-serif font-semibold text-white uppercase block mb-[15px]">
                About Wild Oasis
              </span>
              <p className="text-xmd w-[95%] leading-[36px] md:w-full">
                With cutting-edge technology, Wild Oasis empowers hospitality
                businesses to manage bookings, guest data, and cabins seamlessly
                through real-time updates, dynamic pricing, and user-friendly
                dashboards. Our solutions streamline operations, providing
                instant insights and efficient management to meet the needs of
                both staff and guests.
              </p>
              <Buttons
                ariaLabel="button"
                href="https://wildoasishub.netlify.app/login"
                className="font-medium font-serif uppercase btn-link after:h-[2px] text-[#FFD700] after:bg-darkgray hover:bg-[#FFD700] hover:text-black"
                color="#FFD700"
                target="_blank"
                title="Visit website"
                size="lg"
              />
            </m.div>
          </Row>
          <Row className="row-cols-1 row-cols-lg-4 row-cols-sm-2 mt-36 justify-center md:mt-24 landscape:md:mt-12 xs:mt-[65px] gap-y-10">
            {data.map((item, i) => {
              return (
                <m.div
                  key={i}
                  {...{ ...fadeIn, transition: { delay: 0.2 * i } }}
                >
                  <div className="flex justify-center items-center h-full">
                    <i
                      className={`${item.icon} align-middle text-[28px] text-white mr-[15px]`}
                    ></i>
                    <span className="font-serif font-semibold uppercase leading-[16px]">
                      {item.title}
                    </span>
                  </div>
                </m.div>
              );
            })}
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section
        id="my-role"
        className="py-[160px] bg-lightgray overflow-hidden lg:py-[120px] md:py-[90px] sm:py-[80px] xs:py-[50px]"
      >
        <Container>
          <Row>
            <Col xl={4} lg={5} md={6}>
              <m.h4
                className="font-serif font-semibold text-darkgray -tracking-[1px] sm:text-center xxs:mb-[45px]"
                {...fadeIn}
              >
                <span className="block p-0 sm:text-center tracking-[-1px]">
                  My Role and Tools
                </span>
              </m.h4>
            </Col>
            <m.div
              className="sm:text-center col-xl-5 col-md-6 offset-xl-2 offset-lg-1"
              {...{ ...fadeIn, transition: { delay: 0.5 } }}
            >
              <span className="font-serif font-semibold text-darkgray uppercase block mb-[15px]">
                Frontend Development
              </span>
              <p className="text-xmd w-[95%] leading-[36px] md:w-full">
                As the Frontend Developer for Wild Oasis, I was responsible for
                creating a highly dynamic and interactive user interface using
                React.js. I collaborated with design and backend teams to build
                a seamless experience that integrates real-time booking
                management, user administration, and cabin availability
                tracking. My role also involved performance optimization to
                ensure the dashboard was scalable, responsive, and user-friendly
                across all devices.
              </p>
            </m.div>
          </Row>
          <Row className="mt-36 justify-center md:mt-24 landscape:md:mt-12 xs:mt-[65px] gap-y-10">
            <Col lg={6}>
              <div className="font-serif font-semibold text-darkgray uppercase mb-[15px]">
                Tools & Technologies
              </div>
              <ul className="list-none text-darkgray leading-[28px]">
                <li>React.js (Hooks & Functional Components)</li>
                <li>JavaScript (ES6+)</li>
                <li>React Query for data fetching and caching</li>
                <li>Styled-components for UI styling</li>
                <li>Supabase for database and authentication</li>
                <li>Git & GitHub for version control</li>
                <li>React Context API for state management</li>
              </ul>
            </Col>

            <Col lg={6}>
              <div className="font-serif font-semibold text-darkgray uppercase mb-[15px]">
                Responsibilities
              </div>
              <ul className="list-none text-darkgray leading-[28px]">
                <li>Developing reusable and scalable components</li>
                <li>
                  Creating dynamic booking and cabin management interfaces
                </li>
                <li>Building forms for guest check-in/check-out</li>
                <li>Ensuring cross-browser compatibility</li>
                <li>Implementing state management with React Context API</li>
                <li>Optimizing web performance for faster load times</li>
                <li>Integrating Supabase for real-time database updates</li>
                <li>Debugging and resolving UI issues</li>
                <li>Collaborating with backend and design teams</li>
              </ul>
            </Col>

            <Col lg={6}>
              <div className="font-serif font-semibold text-darkgray uppercase mb-[15px]">
                Features Implemented
              </div>
              <ul className="list-none text-darkgray leading-[28px]">
                <li>Real-time booking management with Supabase</li>
                <li>Dynamic cabin availability and pricing updates</li>
                <li>User management system with role-based access control</li>
                <li>
                  Comprehensive booking tracking system with status updates
                </li>
                <li>Data fetching and caching with React Query</li>
                <li>Dynamic forms for guest check-in/check-out</li>
                <li>
                  Cross-browser compatibility for consistent user experience
                </li>
                <li>Efficient state management using React Context API</li>
              </ul>
            </Col>

            <Col lg={6}>
              <div className="font-serif font-semibold text-darkgray uppercase mb-[15px]">
                Challenges Overcome
              </div>
              <ul className="list-none text-darkgray leading-[28px]">
                <li>Learning and integrating Supabase for the first time</li>
                <li>
                  Managing real-time data updates efficiently with Supabase
                </li>
                <li>Optimizing data fetching and caching with React Query</li>
                <li>
                  Handling dynamic data rendering without affecting performance
                </li>
                <li>
                  Managing state efficiently across multiple components with
                  React Context
                </li>
                <li>
                  Ensuring smooth data synchronization between frontend and
                  Supabase backend
                </li>
                <li>
                  Debugging and resolving issues related to Supabase and React
                  Query integration
                </li>
                <li>
                  Balancing the complexity of new technologies with an intuitive
                  UI
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      {/* <section className="py-[130px] overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px]">
        <Container>
          <Row className="items-center xs:justify-center">
            <Col
              md={4}
              sm={4}
              className="text-right font-serif text-darkgray uppercase text-md font-medium sm:text-end xs:text-center xs:mb-[10px]"
            >
              Share this project
            </Col>
            <Col md={4} sm={2}>
              <div className="w-full h-[1px] bg-mediumgray xs:my-[15px]"></div>
            </Col>
            <Col md={4} sm={6}>
              <SocialIcons
                theme="social-icon-style-01"
                className="text-darkgray xs:justify-center"
                size="sm"
                iconColor="dark"
                data={SocialIconsData}
              />
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* Section End */}

      {/* Section Start */}
      {/* <section className="relative z-[1] bg-lightgray p-0 overflow-hidden">
        <Container fluid className="px-0">
          <Row sm={2} className="row-cols-1 justify-center xs:text-center gx-0">
            <Col className="relative">
              <Link
                to="/portfolio/single-project-page-05"
                className="portfolio_btn h-full px-28 py-16 justify-between items-center flex xl:p-12 md:inline-flex"
              >
                <div
                  className="portfolio_img absolute top-0 left-0 h-full w-full -z-[1] duration-150	bg-no-repeat bg-cover overflow-hidden bg-center"
                  style={{
                    backgroundImage: "url(https://via.placeholder.com/955x185)",
                  }}
                ></div>
                <div className="btn_icon font-medium text-darkgray font-serif mr-uto uppercase">
                  <i className="line-icon-Arrow-OutLeft duration-100	text-[40px] inline-block mr-[20px] align-middle	"></i>
                  <span className="text-sm inline-block md:hidden">
                    Prev project
                  </span>
                </div>
                <span className="portfolio_disc font-medium text-darkgray text-xlg font-serif block lg:text-[18px] lg:leading-[26px] md:text-left">
                  Model photography
                </span>
              </Link>
            </Col>
            <Col className="relative md:flex md:justify-end xs:block">
              <Link
                to="/portfolio/single-project-page-02"
                className="portfolio_btn h-full px-28 py-16 justify-between items-center flex xl:p-12 md:inline-flex"
              >
                <span className="portfolio_disc font-medium text-darkgray text-xlg font-serif block lg:text-[18px] lg:leading-[26px] md:text-right">
                  Daimler financial
                </span>
                <div
                  className="portfolio_img absolute top-0 left-0 h-full w-full -z-[1] duration-150	bg-no-repeat bg-cover overflow-hidden bg-center"
                  style={{
                    backgroundImage: "url(https://via.placeholder.com/955x185)",
                  }}
                ></div>
                <div className="btn_icon font-medium text-darkgray font-serif mr-uto uppercase">
                  <span className="text-sm inline-block md:hidden">
                    Next project{" "}
                  </span>
                  <i className="line-icon-Arrow-OutRight duration-100	text-[40px] inline-block ml-[20px] align-middle	"></i>
                </div>
              </Link>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* Section End */}
    </div>
  );
};

export default WildOasis;
